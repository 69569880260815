import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../api/auth';
import { handleError } from '../../utils/error-handler';
import ErrorFragment from './ErrorFragment';

interface ForgotPasswordProps {
  open: boolean;
  handleClose: () => void;
}

export default function ForgotPassword({ open, handleClose }: ForgotPasswordProps) {
  const navigate = useNavigate();  // Initialize useNavigate hook
  const [successMessage, setSuccessMessage] = React.useState('')
  const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] = React.useState('')

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email") as string;
    
    try {
      const response = await forgotPassword({email});
      if (response.status === 200) {
        console.log({successMessage})
        setSuccessMessage(response.data.message); //Email sent
      }
    } catch (error: any) {
      handleError(error, setForgotPasswordErrorMessage)
    }
    
    handleClose();
    // Navigate to the /reset-password route
    navigate('/reset-password');
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
        sx: { backgroundImage: 'none' },
      }}
    >
      <DialogTitle>Reset password</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <DialogContentText>
          Enter your account&apos;s email address, and we&apos;ll send you a verification code to
          reset your password.
        </DialogContentText>
        <OutlinedInput
          autoFocus
          required
          margin="dense"
          id="email"
          name="email"
          label="Email address"
          placeholder="Email address"
          type="email"
          fullWidth
        />
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button onClick={handleClose}>Cancel</Button>      
        <Button variant="contained" type="submit">
          Continue
        </Button>
        {<ErrorFragment error ={forgotPasswordErrorMessage}/>}
      </DialogActions>
    </Dialog>
  );
}
