import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useNavigate } from 'react-router-dom';
import { verifySignup } from '../../api/auth';
import { handleError, validateCode } from '../../utils/error-handler';
import ErrorFragment from './ErrorFragment';

interface VerifySignupProps{
  open: boolean;
  email: string
  handleClose: () => void;
}

export default function VerifySignup({ open, email, handleClose }: VerifySignupProps) {
  const navigate = useNavigate();  // Initialize useNavigate hook
  const [successMessage, setSuccessMessage] = React.useState('')
  const [verificationCodeError, setVerificationCodeError] = React.useState(false);
  const [verificationCodeErrorMessage, setVerificationCodeErrorMessage] = React.useState('');
  const [accountVerificationErrorMessage, setAccountVerificationErrorMessage] = React.useState('')

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (verificationCodeError){
      console.warn({verificationCodeErrorMessage})
      return;
    }
    const data = new FormData(event.currentTarget);
    const verificationCode = data.get("verificationCode") as string;
    
    try {
      const response = await verifySignup({email, verificationCode});
      if (response.status === 200) {
        console.log({successMessage})
        setSuccessMessage(response.data.message);
      }
    } catch (error: any) {
      handleError(error, setAccountVerificationErrorMessage)
    }
    handleClose();
    navigate('/sign-in');
  };

  const validateInputs = () => {
    const verificationCode = document.getElementById('verificationCode') as HTMLInputElement;
    let isValid = true;
    if (!verificationCode.value || !validateCode(verificationCode.value)) {
      setVerificationCodeError(true);
      setVerificationCodeErrorMessage('Verification code must be 6 numbers.');
      isValid = false;
    } else {
      setVerificationCodeError(false);
      setVerificationCodeErrorMessage('');
    }
    return isValid;
  };
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
        sx: { backgroundImage: 'none' },
      }}
    >
      <DialogTitle>Verify account</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <DialogContentText>
          Enter 6 digit account verification code from your email address.
        </DialogContentText>
        <OutlinedInput
          autoFocus
          required
          margin="dense"
          id="verificationCode"
          name="verificationCode"
          label={email}
          placeholder="Verification code sent to email"
          error={verificationCodeError}
          type="text"
          fullWidth
          color={verificationCodeError ? 'error' : 'primary'}
        />
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button onClick={handleClose}>Cancel</Button>      
        <Button variant="contained" onClick={validateInputs} type="submit" >
          Continue
        </Button>
        {<ErrorFragment error ={accountVerificationErrorMessage}/>}
      </DialogActions>
    </Dialog>
  );
}
