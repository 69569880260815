import React from 'react';
import {Routes, Route} from 'react-router-dom';
import SignIn from './pages/SignIn';
import SignUp from './pages/Signup';
import HomePage from './components/HomePage';
import ResetPassword from './pages/ResetPassword';
import Restaurants from './components/Restaurants';
const App: React.FC = () => {

  return (
    <>
      <Routes>
        <Route path="/home" element={<HomePage />} />
        <Route path='/welcome' element={<Restaurants sport='basketball'/>}/>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />}/>
      </Routes>
    </>
  );
};

export default App;
