import { backendURL } from "../utils/base-url";
import axios from "axios";
const api = axios.create({
  baseURL: backendURL
});


// Signup API function
export const signup = (data: { email: string; password: string; name:string }) => {
  return api.post("/auth/signup", data);
};

// Login API function
export const signin = (data: { email: string; password: string }) => {
  return api.post("/auth/signin", data);
};

// Verify signup API function
export const verifySignup = (data: { email: string; verificationCode: string }) => {
  return api.post("/auth/verify-signup", data);  // Endpoint to verify the signup with the verification code
};

// Forgot password API function (sends the verification code)
export const forgotPassword = (data: { email: string }) => {
  return api.post("/auth/forgot-password", data);  // Endpoint to request password reset
};

// Confirm forgot password API function (verifies the code and sets a new password)
export const confirmForgotPassword = (data: { email: string, verificationCode: string, newPassword: string }) => {
  return api.post("/auth/confirm-forgot-password", data);  // Endpoint to confirm password reset
};



