type Props = {
    error: string;
  };
  
  const ErrorFragment: React.FC<Props> = ({ error }) => {
    return (
      <span style={{ color: "#C20A0A"}}>
        {error}
      </span>
    );
  };
  
  export default ErrorFragment;