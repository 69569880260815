export const handleError = (error: any, setErrorMessage: React.Dispatch<React.SetStateAction<string>>) => {
  let errorMessage = "Unexpected error: Please try again later.";

  if (error.response) {
    // Check if the server returned a 500 status code and a specific error message
    if (error.response.status === 500 && error.response.data?.error) {
      errorMessage = error.response.data.error; // Specific error message from the server
    } else {
      // Handle other cases with a generic unexpected error message
      errorMessage = `Unexpected error: ${error.message}`;
    }
  } else {
    // Handle errors without a response (e.g., network issues)
    console.error("Unexpected error:", error.message);
  }

  setErrorMessage(errorMessage); // Update UI with the error message
};


//password validator
export function validatePassword(password: string): boolean {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
  return regex.test(password);
}

//verification code validator
export function validateCode(code:string): boolean {
  const regex = /^\d{6}$/;
  return regex.test(code)
}