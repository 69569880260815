import React, { useState } from "react";
import "./HomePage.css";
const HomePage: React.FC = () => {
  // Mock scores data in ESPN style
  const sportsScores = [
    {
      sport: "Football",
      team1: { name: "Team A", score: 3, logo: "https://via.placeholder.com/20" },
      team2: { name: "Team B", score: 1, logo: "https://via.placeholder.com/20" },
      status: "LIVE",
    },
    {
      sport: "Tennis",
      team1: { name: "Player 1", score: 2, logo: "https://via.placeholder.com/20" },
      team2: { name: "Player 2", score: 0, logo: "https://via.placeholder.com/20" },
      status: "FINAL",
    },
    {
      sport: "Basketball",
      team1: { name: "Team X", score: 101, logo: "https://via.placeholder.com/20" },
      team2: { name: "Team Y", score: 99, logo: "https://via.placeholder.com/20" },
      status: "FINAL",
    },
    // Add more scores to test scrolling
    {
      sport: "Baseball",
      team1: { name: "Team Z", score: 5, logo: "https://via.placeholder.com/20" },
      team2: { name: "Team W", score: 4, logo: "https://via.placeholder.com/20" },
      status: "LIVE",
    },
    {
      sport: "Soccer",
      team1: { name: "Team M", score: 2, logo: "https://via.placeholder.com/20" },
      team2: { name: "Team N", score: 1, logo: "https://via.placeholder.com/20" },
      status: "FINAL",
    },
  ];

  const [scrollIndex, setScrollIndex] = useState(0);

  const handleScrollLeft = () => {
    if (scrollIndex > 0) {
      setScrollIndex(scrollIndex - 1);
    }
  };

  const handleScrollRight = () => {
    if (scrollIndex < sportsScores.length - 1) {
      setScrollIndex(scrollIndex + 1);
    }
  };

  return (
    <div className="home-page">
      <h2>Global Sports Scores</h2>
      
      {/* Score Navigation */}
      <div className="score-bar">
        <button className="scroll-button left" onClick={handleScrollLeft}>
          &lt;
        </button>

        <div className="score-list">
          <div className="score-card-container">
            {sportsScores.slice(scrollIndex, scrollIndex + 3).map((score, index) => (
              <div key={index} className="score-card">
                <div className="score-header">
                  <h3>{score.sport}</h3>
                </div>
                <div className="score-details">
                  <div className="team">
                    <img src={score.team1.logo} alt={score.team1.name} />
                    <span>{score.team1.name}</span>
                    <span className="score">{score.team1.score}</span>
                  </div>
                  <div className="vs">VS</div>
                  <div className="team">
                    <img src={score.team2.logo} alt={score.team2.name} />
                    <span>{score.team2.name}</span>
                    <span className="score">{score.team2.score}</span>
                  </div>
                </div>
                <div className={`status ${score.status.toLowerCase()}`}>
                  {score.status}
                </div>
              </div>
            ))}
          </div>
        </div>

        <button className="scroll-button right" onClick={handleScrollRight}>
          &gt;
        </button>
      </div>

      <h1>Latest Sports News</h1>
      <div className="article-list">
        {/* Your article list here */}
      </div>
   
    </div>
  );
};

export default HomePage;
