import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { GOOGLE_API_KEY } from '../config/google-api-key';
import { mapAPI } from '../utils/base-url';

interface Venue {
  name: string;
  address: string;
  latitude: number;
  longitude: number;
}

interface RestaurantsProps {
  sport: string;
}

const containerStyle = {
  width: '100%',
  height: '400px',
};

const Restaurants: React.FC<RestaurantsProps> = ({ sport }) => {
  const [venues, setVenues] = useState<Venue[]>([]);
  const [error, setError] = useState<string | null>(null);
  const baseURL = mapAPI;

  const fetchRestaurants = async () => {
    const lat = 37.902977;
    const lng = -122.3019144;
    // when pushing to the server, be mindful of making the server dynamic
    try {
      const response = await fetch(
        `${baseURL}/api/places?lat=${lat}&lng=${lng}&sport=${sport}`
      );
      

      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }

      const data = await response.json();

      //console.log('API Response:', data); // Log response to debug

      if (data.results && data.results.length > 0) {
        const venues = data.results.map((venue: any) => ({
          name: venue.name,
          address: venue.vicinity, // Use `vicinity` for Google Places API
          latitude: venue.geometry.location.lat,
          longitude: venue.geometry.location.lng,
        }));
        setVenues(venues);
      } else {
        setError('No venues found');
      }
    } catch (err) {
      setError('Error fetching venues');
      console.error(err);
    }
  };

  useEffect(() => {
    fetchRestaurants();
  }, [sport]);

  return (
    <div>
      <h1>Nearby {sport} Venues</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {!error && venues.length === 0 && <p>Loading venues...</p>}
      <LoadScript googleMapsApiKey={GOOGLE_API_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{ lat: 37.902977, lng: -122.3019144 }}
          zoom={12}
        >
          {venues.map((venue, index) => (
            <Marker
              key={index}
              position={{
                lat: venue.latitude,
                lng: venue.longitude,
              }}
              title={venue.name}
            />
          ))}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default Restaurants;
